
import { Component, Vue } from 'vue-property-decorator'
import { DeviceInfo, MapList } from '@/types/intelligentControl'

@Component
export default class PondMonitorDetailList extends Vue {
  private searchInfo = { deviceNumber: '' }
  private tableData: { loading: boolean; data: DeviceInfo[] } = {
    loading: false,
    data: []
  }

  private childTableData: { loading: boolean; data: DeviceInfo[] } = {
    loading: false,
    data: []
  }

  private page = 1
  private size = 10
  private total = 0
  private expands: Array<string> = []

  created () {
    this.getData()
  }

  get projectId () {
    return this.$route.params.projectId as string || ''
  }

  // 获取列表数据
  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.intelligentControl.selectControlPage, {
      ...this.searchInfo,
      projectId: this.projectId,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
      this.getChildList(res.list[0], res.list)
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 获取子设备列表
  getChildList (row?: any, expandedRows?: any) {
    this.childTableData.data = []
    if (expandedRows.length) {
      if (row) {
        this.expands = []
        this.expands.push(row.deviceId)
      }
    } else {
      this.expands = []
    }
    this.$axios.get(this.$apis.intelligentControl.selectControlById, {
      deviceId: row.deviceId,
      deviceTypeId: '1025'
    }).then(res => {
      this.handleData(res)
    })
  }

  handleData (res: MapList) {
    if (res.do1Name) {
      this.childTableData.data.push({
        name: res.do1Name,
        state: res.do1State,
        deviceId: res.deviceId,
        controlRoute: '1'
      })
    }
    if (res.do2Name) {
      this.childTableData.data.push({
        name: res.do2Name,
        state: res.do2State,
        deviceId: res.deviceId,
        controlRoute: '2'
      })
    }
    if (res.do3Name) {
      this.childTableData.data.push({
        name: res.do3Name,
        state: res.do3State,
        deviceId: res.deviceId,
        controlRoute: '3'
      })
    }
    if (res.do4Name) {
      this.childTableData.data.push({
        name: res.do4Name,
        state: res.do4State,
        deviceId: res.deviceId,
        controlRoute: '4'
      })
    }
  }

  getRowKeys (row: any) {
    return row.deviceId
  }

  // 开关
  onSwitch (info: DeviceInfo) {
    const oldValue = info.state === '1' ? '0' : '1'
    this.$confirm(`确认${this.getText(info.state)}吗, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.intelligentControl.insertControl, {
        deviceId: info.deviceId,
        operateRoute: info.controlRoute,
        operateCommand: info.state
      }).then(() => {
        this.$message.success(`${this.getText(info.state)}成功!`)
      }).catch(() => {
        info.state = oldValue
      })
    }).catch(() => {
      info.state = oldValue
    })
  }

  // 获取状态
  getText (state: string) {
    const data = ['关闭', '开启']
    return data[+state]
  }

  // 绑定设备
  onBindDevice (id: string) {
    this.$router.push({
      name: 'intelligentControlBindDevice',
      params: { projectId: id }
    })
  }

  // 历史数据
  toHistory (info: MapList) {
    this.$router.push({
      name: 'intelligentControlListHistory',
      query: { controlDeviceNumber: info.deviceNumber },
      params: { deviceId: info.deviceId, projectId: this.projectId }
    })
  }
}
